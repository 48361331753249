import classNames from "classnames";
import { Icons } from "assets";
import styles from "./style.module.scss";

const PrivacyPolicy = () => {
  return (
    <section>
      <div className={classNames(styles.header)}>
        <div className={classNames(styles.customContainer, "p-6")}>
          <div className={classNames(styles.text, "w-full justify-between")}>
            <div className="">
              <Icons.LogoIcon className={classNames(styles.icon)} />
            </div>
            <h2 className=" mt-2">Privacy Policy</h2>
            <div className={classNames(styles.icon)} />
          </div>
        </div>
      </div>

      <div className={classNames(styles.customContainer, "p-6")}>
        <div className={classNames(styles.main, "flex flex-col gap-4")}>
          <div className="flex flex-col gap-3">
            <p>
              <strong>User Agreement</strong>
            </p>
            <p>Privacy Policy</p>
            <p>Effective November 2019</p>
          </div>
          <div className="flex flex-col gap-6">
            <p>
              Welcome to the Federal Laboratory Consortium for Technology
              Transfer (FLC) Website (or sometimes called “site”) which is
              provided as a service of AUTM under a Federal Cooperative
              Agreement.{" "}
            </p>
            <p>
              {" "}
              1. <strong>Agreement.</strong> By using the FLC Website, you agree
              to these Terms and Conditions of Use (Terms of Use). The FLC and
              AUTM reserve the right to change these Terms of Use from time to
              time. The current “Terms and Conditions of Use Agreement” will
              always be posted on the Website, and you may view it at any time
              through the link at the bottom of any public page of the Website.
            </p>
            <p>
              2. <strong>Description of Service.</strong> Federallabs.org
              consists of several sections, some of which have restricted
              access.
            </p>
            <p>
              3. <strong> Intellectual Property.</strong> The entire contents
              and design of the Website, including all trademarks, logos, trade
              names, documents, databases, graphic representations, and other
              information, are the property of the FLC and /or AUTM, or used by
              AUTM with permission, and are protected under U.S. and
              international copyright and trademark laws, whether or not a
              notice of copyright, trademark, or other proprietary rights
              appears on the screen displaying the information. Except as
              otherwise provided herein, users of the Website may save and use
              information contained on the Website only for personal or other
              non- commercial, educational purposes. No other use, including,
              without limitation, reproduction, retransmission or editing, of
              Website information may be made without the prior written
              permission of AUTM, which may be requested by contacting the FLC
              or AUTM at{" "}
              <a
                href="mailto:info@federallabs.org"
                data-feathr-click-track="true"
                data-feathr-link-aids="63375483d5efe59e0d8f0e91,633754242901f89c2b26930e"
              >
                info@federallabs.org
              </a>
            </p>
            <p>
              4. <strong>Limitation of Liability.</strong> In no event shall
              FLC/AUTM or its officers, directors, members, staff, or agents, be
              liable for any damages of any kind, including, without limitation,
              any special, incidental, indirect, or consequential damages,
              whether or not advised of the possibility of such damages, and on
              any theory of liability whatsoever, arising out of, or in
              connection with, the use or performance of the Website or any
              content appearing on the Website.
            </p>
            <p>
              5. <strong>Disclaimer.</strong> FLC/AUTM makes no warranty,
              guaranty, or representation regarding the accuracy, content,
              completeness, reliability, operability, orlegality of information
              contained within the Website, including, without limitation, the
              warranties of merchantability, fitness for a particular use,
              andnon-infringement of propriety rights. The information,
              opinions, and recommendations presented within the Website are for
              general informationonly. Such information should not be considered
              medical advice and is not intended to replace consultation with a
              qualified physician. Unlessspecifically stated otherwise, FLC/AUTM
              does not endorse, approve, recommend, or certify any information,
              product, process, service, ororganization presented or mentioned
              on the Website, and information from the Website should not be
              referenced in any way to imply suchapproval or endorsement.
              Moreover, FLC/AUTM makes no warranty that the Website, or the
              server that makes it available, is free from viruses,worms, or
              other elements or codes that manifest contaminating or destructive
              properties. FLC/AUTM expressly disclaims any and all liability
              orresponsibility for any direct, indirect, incidental,
              consequential, special or other damages arising out of any
              individual’s use of, reference to, relianceon, or inability to
              use, the Website or the information presented on the Website. In
              any jurisdiction that does not permit such a disclaimer of
              liability,FLC/AUTM’s liability shall be limited to the greatest
              extent allowed by applicable law.
            </p>
            <p>
              6. <strong>Links.</strong> Links or pointers connecting the
              Website with other Internet websites are provided as a courtesy
              only and do not imply, directly orindirectly, the endorsement,
              sponsorship, or approval by AUTM of the linked website, the
              organization or individual operating the website, or anyproduct,
              service, individual, or organization referenced in the Website. In
              general, any website that has an address (or URL) that does not
              contain“AUTM.net” is a linked website. The content of any linked
              website does not necessarily reflect the opinions, standards or
              policies of AUTM. Linkedwebsites are not under the control of
              AUTM, and AUTM is not responsible for the content of any linked
              website, any links contained within a linkedwebsite, any changes
              or updates to such websites, or the compliance with applicable
              laws of such linked websites.
            </p>
            <p>
              7. <strong>DMCA Notice and Takedown Procedures.</strong> AUTM
              abides by the federal Digital Millennium Copyright Act (DMCA) by
              responding to notices ofalleged infringement that comply with the
              DMCA and other applicable laws. As part of its response, AUTM may
              remove or disable access tomaterial on the Website that is claimed
              to be infringing, in which case AUTM will make a good-faith
              attempt to contact the person who submittedthe affected material
              so that person may make a counter notification, also in accordance
              with the DMCA. By posting material to the Website, theposting
              party represents and warrants that he or she owns the copyright
              with respect to such material or has received permission from
              thecopyright owner. In addition, the posting party grants AUTM and
              users of the Website the nonexclusive, unrestricted, royalty-free
              right and licenseto display, copy, publish, distribute, transmit,
              print, and use such information or other material. Anyone who
              believes that material posted on theWebsite infringes on his or
              her copyrighted work should refer to the{" "}
              <a
                href="http://www.autm.net/AUTMMain/media/About/Documents/AUTM_Procedures_for_Requesting_Removal_of_Infringing_Material_MAY2018.pdf"
                data-feathr-click-track="true"
                data-feathr-link-aids="63375483d5efe59e0d8f0e91,633754242901f89c2b26930e"
              >
                Procedures for Requesting Removal of Infringing Material
              </a>
            </p>
            <p>
              8. <strong>Choice of Law and Forum.</strong> This Agreement is
              entered into and performed in the State of Illinois, United States
              of America, and is governed bythe laws of Illinois, exclusive of
              its choice of law or conflict of provisions. In any claim or
              action directly or indirectly arising under this Agreementor
              related to AUTM.net, each party irrevocably submits to the
              exclusive personal jurisdiction of the state courts located in
              Cook County, Illinois, orthe United States District Court for the
              Northern District of Illinois, whichever has jurisdiction, and
              each party waives any jurisdictional venue orinconvenient forum
              objections to such court.
            </p>
            <p>
              9. <strong>Complete Agreement.</strong> If any provision or
              provisions of this Agreement are held by a court or other tribunal
              of competent jurisdiction not to beenforceable, then such
              provisions shall be limited or eliminated to the minimum extent
              necessary so that this Agreement shall otherwise remain infull
              force and effect. These Terms of Use supersede any other
              statements included elsewhere on the Website, which are
              inconsistent or conflictingwith these Terms of Use.
            </p>
            <p>
              10. <strong> Amendments.</strong> AUTM reserves the right to
              modify or change the terms and rules for use of the Website as it
              determines from time-to-time inthe best interests of AUTM. Use of
              the Website constitutes the user’s continuing agreement to be
              bound by these Terms of Use as they areamended from time to time.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
